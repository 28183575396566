import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import PropTypes from "prop-types";
import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProduct } from "store/slices/productsSlice";
import {
  LCDSizeOptions,
  RAMSizeOptions,
  ROMSizeOptions,
  colorOptions,
  fashionSizeOptions,
  footwearSizeOptions,
  productOptions,
  weightOptions,
} from "utils/constants/productForm";
import { reduceAttributes } from "utils/helpers/productForm";
import { toast } from "utils/hooks/useToast";
function ProductOptionsSections({ formik }) {
  const dispatch = useDispatch();
  const [productOption, setProductOption] = useState(null);
  const selectedCategory = formik.values?.category;
  const loading = useSelector((state) => state.products.productLoading);

  const handleRemoveAttribute = (attribute) => {
    formik.setFieldValue(
      "attributes",
      formik.values?.attributes?.filter((attr) => attr !== attribute)
    );
    formik.setFieldValue(attribute, []);
  };

  const sizeOptions =
    selectedCategory?.categoryTree.includes("647ee7b89c9a0126548ea9d0") ||
    selectedCategory?.categoryTree.includes("603e0dcc7e4d2a1f859d8a63")
      ? footwearSizeOptions
      : selectedCategory?.categoryTree.includes("647ee7a69c9a0126548ea776") ||
        selectedCategory?.categoryTree.includes("6038dcbe7e4d2a1f859d8253")
      ? fashionSizeOptions
      : selectedCategory?.categoryTree.includes("604a18e905ec9502c9f8d4b7") ||
        selectedCategory?.categoryTree.includes("610ce6f01f0eb1002eb80872") ||
        selectedCategory?.categoryTree.includes("647ee7d49c9a0126548eabdf") ||
        selectedCategory?.categoryTree.includes("647ee7d49c9a0126548eabe0")
      ? LCDSizeOptions
      : fashionSizeOptions;

  const handleSubmit = useCallback(() => {
    const _data = {
      productName: formik.values.name,
      attributes: reduceAttributes(formik.values),
    };

    dispatch(updateProduct({ productId: formik.values.id, body: _data }))
      .unwrap()
      .then(() => {
        toast.success("Options updated successfully");
      });
  }, [dispatch, formik]);

  return (
    <StyledMuiCard
      title="Product Options"
      subTitle="Add product options like color, size, etc"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={3}
          p={{ xs: 1, md: 1.5 }}
        >
          <Stack direction="row" gap={2}>
            <Autocomplete
              name="productOption"
              options={productOptions}
              value={productOption}
              onChange={(_, option) => setProductOption(option)}
              onBlur={formik.handleBlur}
              getOptionDisabled={(option) => {
                if (!selectedCategory) return true;
                let isDisabled = true;
                option?.categories?.forEach((optionCategory) => {
                  selectedCategory?.categoryTree?.forEach((TreeCategory) => {
                    if (optionCategory === TreeCategory) {
                      isDisabled = false;
                    }
                  });
                });
                return isDisabled;
              }}
              fullWidth
              sx={{ maxWidth: 460 }}
              renderInput={(params) => (
                <StyledTextField
                  fullWidth
                  {...params}
                  label="Options"
                  error={Boolean(
                    formik.touched.productOption && formik.errors.productOption
                  )}
                  helperText={
                    formik.touched.productOption &&
                    !!formik.errors.productOption
                      ? formik.errors.productOption
                      : "Please select the appropriate attribute"
                  }
                  sx={{ maxWidth: 460 }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
            />
            <Button
              color="primary"
              variant="outlined"
              disableElevation
              onClick={() =>
                formik.setFieldValue("attributes", [
                  ...formik.values.attributes,
                  productOption.value,
                ])
              }
              sx={{
                fontWeight: "bold",
                minWidth: { md: 100, xl: 160 },
                height: { xs: 50, xl: 56 },
              }}
            >
              Add
            </Button>
          </Stack>

          {/** Product Color Options */}
          {formik.values.attributes?.includes("color") && (
            <>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Color
              </Typography>
              <Stack direction="row" gap={2}>
                <Autocomplete
                  multiple
                  name="color"
                  fullWidth
                  options={colorOptions}
                  value={formik.values.color}
                  onChange={(_, options) => {
                    formik.setFieldValue("color", options);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      placeholder="Color"
                      error={Boolean(
                        formik.touched.color && formik.errors.color
                      )}
                      helperText={
                        formik.touched.color && !!formik.errors.color
                          ? formik.errors.color
                          : "Please select the appropriate attribute"
                      }
                    />
                  )}
                />
                <IconButton
                  color="primary"
                  variant="outlined"
                  sx={{
                    height: { xs: 50, xl: 56 },
                    borderRadius: 0,
                  }}
                  onClick={() => handleRemoveAttribute("color")}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Stack>
            </>
          )}

          {/* Product Size Options */}
          {formik.values.attributes?.includes("size") && (
            <>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Size
              </Typography>
              <Stack direction="row" gap={2}>
                <Autocomplete
                  multiple
                  name="size"
                  fullWidth
                  value={formik.values.size}
                  onChange={(_, options) => {
                    formik.setFieldValue("size", options);
                  }}
                  onBlur={formik.handleBlur}
                  options={sizeOptions}
                  // getOptionSelected={getOptionSelected}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      placeholder="Size"
                      error={Boolean(formik.touched.size && formik.errors.size)}
                      helperText={
                        formik.touched.size && !!formik.errors.size
                          ? formik.errors.size
                          : "Please select the appropriate attribute"
                      }
                    />
                  )}
                />
                <IconButton
                  color="primary"
                  variant="outlined"
                  sx={{
                    height: { xs: 50, xl: 56 },
                    borderRadius: 0,
                  }}
                  onClick={() => handleRemoveAttribute("size")}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Stack>
            </>
          )}

          {/* Product Weight Options */}
          {formik.values.attributes?.includes("weight") && (
            <>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Weight
              </Typography>
              <Stack direction="row" gap={2}>
                <Autocomplete
                  multiple
                  name="weight"
                  fullWidth
                  value={formik.values.weight}
                  onChange={(_, options) => {
                    formik.setFieldValue("weight", options);
                  }}
                  onBlur={formik.handleBlur}
                  options={weightOptions}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      placeholder="Weight"
                      error={Boolean(
                        formik.touched.weight && formik.errors.weight
                      )}
                      helperText={
                        formik.touched.weight && !!formik.errors.weight
                          ? formik.errors.weight
                          : "Please select the appropriate attribute"
                      }
                    />
                  )}
                />
                <IconButton
                  color="primary"
                  variant="outlined"
                  sx={{
                    height: { xs: 50, xl: 56 },
                    borderRadius: 0,
                  }}
                  onClick={() => handleRemoveAttribute("weight")}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Stack>
            </>
          )}

          {/* Product RAM Options */}
          {formik.values.attributes?.includes("ram") && (
            <>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                RAM
              </Typography>
              <Stack direction="row" gap={2}>
                <Autocomplete
                  multiple
                  name="ram"
                  fullWidth
                  value={formik.values.ram}
                  onChange={(_, options) => {
                    formik.setFieldValue("ram", options);
                  }}
                  onBlur={formik.handleBlur}
                  options={RAMSizeOptions}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      placeholder="RAM"
                      error={Boolean(formik.touched.ram && formik.errors.ram)}
                      helperText={
                        formik.touched.ram && !!formik.errors.ram
                          ? formik.errors.ram
                          : "Please select the appropriate attribute"
                      }
                    />
                  )}
                />
                <IconButton
                  color="primary"
                  variant="outlined"
                  sx={{
                    height: { xs: 50, xl: 56 },
                    borderRadius: 0,
                  }}
                  onClick={() => handleRemoveAttribute("ram")}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Stack>
            </>
          )}

          {/* Product ROM Options */}
          {formik.values.attributes?.includes("rom") && (
            <>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                ROM
              </Typography>
              <Stack direction="row" gap={2}>
                <Autocomplete
                  multiple
                  name="rom"
                  fullWidth
                  value={formik.values.rom}
                  onChange={(_, options) => {
                    formik.setFieldValue("rom", options);
                  }}
                  onBlur={formik.handleBlur}
                  options={ROMSizeOptions}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      placeholder="ROM"
                      error={Boolean(formik.touched.rom && formik.errors.rom)}
                      helperText={
                        formik.touched.rom && !!formik.errors.rom
                          ? formik.errors.rom
                          : "Please select the appropriate attribute"
                      }
                    />
                  )}
                />
                <IconButton
                  color="primary"
                  variant="outlined"
                  sx={{
                    height: { xs: 50, xl: 56 },
                    borderRadius: 0,
                  }}
                  onClick={() => handleRemoveAttribute("rom")}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Stack>
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleSubmit}
            disabled={loading || formik.values.attributes.length === 0}
            sx={{
              fontWeight: "bold",
              width: { md: 100 },
            }}
          >
            Save
          </Button>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

ProductOptionsSections.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(ProductOptionsSections);
