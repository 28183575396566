import RemoveCircle from "@mui/icons-material/RemoveCircle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProductAddImagePlaceholder from "assets/icons/ProductAddImagePlaceholder.svg";
import ProductVideoPlaceholder from "assets/icons/ProductVideoPlaceholder.svg";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVideo,
  removeGalleryImage,
  uploadGalleryImages,
  uploadMainImage,
  uploadVideo,
} from "services/Product";
import {
  setImageValidationError,
  setIsImageValidationDialogOpen,
} from "store/slices/uiSlice";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import validateImageAspectRatio from "utils/helpers/validateImageAspectRatio";
import { toast } from "utils/hooks/useToast";
import GalleryImagePlaceholder from "./GalleryImagePlaceholder";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 150;

function MediaSection({ formik, handleDisabled }) {
  const dispatch = useDispatch();
  const mainImageInputRef = useRef(null);
  const galleryImageRef = useRef(null);
  const videoInputRef = useRef(null);
  const [crop, setCrop] = useState();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const productGalleryImages = useSelector(
    (state) => state.products.product?.gallery ?? []
  );
  const productVideo = useSelector(
    (state) => state.products.product?.videoUrl || ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [video, setVideo] = useState("");
  const [galleryImagesError, setGalleryImagesError] = useState("");
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const imgRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [tempImage, setTempImage] = useState(null);

  const mainImageHandler = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        // function params
        // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
        validateImageAspectRatio(image, 500, 500, false, true, 1 / 1, "1 / 1")
          .then((img) => {
            mainImageInputRef.current.value = "";
            uploadMainImage(formik.values.id, img)
              .then((response) => {
                const responseData = getResponseData(response);
                responseData?.mainImage &&
                  formik.setFieldValue("mainImage", responseData?.mainImage);
              })
              .catch((error) => toast.error(getErrorMessage(error)));
          })
          .catch(() => {
            mainImageInputRef.current.value = "";
            setTempImage(image);
            const reader = new FileReader();
            reader.onload = () => {
              setImgSrc(reader.result);
              setIsCropModalOpen(true);
            };
            reader.readAsDataURL(image);
          });
      }
    },
    [formik]
  );

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const handleCropConfirm = () => {
    if (imgRef.current && crop.width && crop.height) {
      const canvas = document.createElement("canvas");
      const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
      const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        imgRef.current,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob((blob) => {
        if (blob) {
          const croppedFile = new File([blob], tempImage.name, {
            type: tempImage.type,
          });

          uploadMainImage(formik.values.id, croppedFile)
            .then((response) => {
              const responseData = getResponseData(response);
              if (responseData?.mainImage) {
                formik.setFieldValue("mainImage", responseData.mainImage);
                setIsCropModalOpen(false);
              }
            })
            .catch((error) => toast.error(getErrorMessage(error)));
        }
      }, "image/jpeg");
    }
  };

  const galleryImageHandler = useCallback(
    async (e) => {
      if (galleryImages.length + e.target.files.length > 6) {
        return setGalleryImagesError("You can upload maximum 6 images");
      } else {
        const images = [];

        for (const image of e.target.files) {
          try {
            // function params
            // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
            const validatedImage = await validateImageAspectRatio(
              image,
              500,
              500,
              false,
              true,
              1 / 1,
              "1 / 1"
            );
            images.push(validatedImage);
          } catch (error) {
            galleryImageRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(
              setImageValidationError(toast.error(getErrorMessage(error)))
            );
            break; // Exit the function on error
          }
        }

        if (images?.length) {
          uploadGalleryImages(formik.values.id, images)
            .then((response) => {
              const responseData = getResponseData(response);
              setGalleryImages(responseData.gallery);
            })
            .catch((error) => toast.error(toast.error(getErrorMessage(error))));
        }
      }
    },
    [galleryImages, formik.values.id, dispatch]
  );

  const handleRemoveGalleryImage = useCallback(
    (index) => {
      removeGalleryImage(formik.values.id, galleryImages[index], index)
        .then((response) => {
          const responseData = getResponseData(response);
          setGalleryImages(responseData.gallery);
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          toast.error(message);
        });
    },
    [formik.values.id, galleryImages]
  );

  const videoUploadHandler = useCallback(
    (e) => {
      setIsLoading(true);
      handleDisabled(true);
      uploadVideo(formik.values.id, e.target.files[0])
        .then((response) => {
          const responseData = getResponseData(response);
          responseData?.videoUrl && setVideo(responseData.videoUrl);
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          toast.error(message);
        })
        .finally(() => {
          setIsLoading(false);
          handleDisabled(false);
        });
    },
    [handleDisabled, formik.values.id, setVideo]
  );

  const handleDeleteVideo = useCallback(() => {
    deleteVideo(formik.values.id, video);
  }, [formik.values.id, video]);

  useEffect(() => {
    setGalleryImages(productGalleryImages);
  }, [productGalleryImages]);

  useEffect(() => {
    productVideo && setVideo(productVideo);
  }, [productVideo]);

  return (
    <StyledMuiCard
      title="Media Section"
      subTitle="Upload images and videos relevant to the product ( Allowed image formats: JPEG, JPG, PNG )"
    >
      <CardContent>
        <Box display="flex" flexWrap="wrap" gap={4}>
          {/* Product Main Image */}
          <Stack direction="column" gap={2}>
            <Stack direction="row" gap={1} alignItems="center">
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Main Image
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                fontSize="0.85rem"
                color="text.secondary"
              >
                (500*500 pixels)
              </Typography>
            </Stack>
            <Box>
              <img
                src={
                  formik.values.mainImage
                    ? formik.values.mainImage
                    : ProductAddImagePlaceholder
                }
                alt="Product Main Media"
                width={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                height={isSmall ? "150px" : isLarge ? "350px" : "400px"}
                style={{ cursor: "pointer" }}
                onClick={() => mainImageInputRef.current.click()}
              />
              <input
                type="file"
                ref={mainImageInputRef}
                style={{ display: "none" }}
                accept="image/x-png,image/png,image/jpeg,image/jpg"
                onChange={mainImageHandler}
              />
              {formik?.errors?.mainImage && (
                <Typography variant="subtitle1" color="error">
                  {formik.errors.mainImage}
                </Typography>
              )}
              <Dialog
                open={isCropModalOpen}
                onClose={() => setIsCropModalOpen(false)}
              >
                <Box
                  sx={{
                    p: 4,
                    backgroundColor: "white",
                    margin: "auto",
                  }}
                >
                  <Typography variant="h6" mb={2}>
                    Adjust Image
                  </Typography>
                  {imgSrc && (
                    <ReactCrop
                      crop={crop}
                      onChange={(newCrop) => setCrop(newCrop)}
                      circularCrop
                      keepSelection
                      aspect={ASPECT_RATIO}
                      minWidth={MIN_DIMENSION}
                    >
                      <img
                        ref={imgRef}
                        src={imgSrc}
                        alt="Upload"
                        style={{ maxHeight: "70vh" }}
                        onLoad={onImageLoad}
                      />
                    </ReactCrop>
                  )}
                  <Box mt={2} textAlign="right">
                    <Button
                      onClick={handleCropConfirm}
                      variant="contained"
                      color="primary"
                    >
                      Confirm Crop
                    </Button>
                  </Box>
                </Box>
              </Dialog>
            </Box>
          </Stack>

          {/* Product Gallery Images */}
          <Stack
            direction="column"
            gap={2}
            height="100%"
            justifyContent="space-between"
          >
            <Stack direction="column" gap={2}>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Gallery Images
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  fontSize="0.85rem"
                  color="text.secondary"
                >
                  (500*500 pixels)
                </Typography>
              </Stack>
              <Box display="flex" flexDirection="row" gap={2}>
                {galleryImages?.length < 6 && (
                  <img
                    ref={galleryImageRef}
                    src={ProductAddImagePlaceholder}
                    alt="Add Product Gallery Placeholder"
                    height="auto"
                    width="150px"
                    style={{ cursor: "pointer" }}
                    onClick={() => galleryImageRef.current.click()}
                  />
                )}
                {!galleryImages?.length
                  ? [...Array(4)]?.map((e, i) => (
                      <Box key={i}>
                        <GalleryImagePlaceholder />
                      </Box>
                    ))
                  : galleryImages?.map((image, index) => (
                      <Stack
                        direction="row"
                        key={index}
                        alignItems="flex-start"
                      >
                        <img src={image} alt="" height="auto" width="150px" />
                        <IconButton
                          disabled={isLoading}
                          onClick={() => handleRemoveGalleryImage(index)}
                        >
                          <RemoveCircle sx={{ color: "#ff0000" }} />
                        </IconButton>
                      </Stack>
                    ))}
              </Box>
              {galleryImagesError && (
                <Typography variant="subtitle1" color="error">
                  {galleryImagesError}
                </Typography>
              )}
              <input
                type="file"
                ref={galleryImageRef}
                style={{ display: "none" }}
                id="upload-product-gallery-images"
                hidden
                multiple
                accept="image/x-png,image/png,image/jpeg,image/jpg"
                onChange={galleryImageHandler}
              />
            </Stack>

            {/* Product Video */}
            <Stack direction="column" gap={2}>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Product Video
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  fontSize="0.85rem"
                  color="text.secondary"
                >
                  (Less then 350MB)
                </Typography>
              </Stack>
              {!isLoading ? (
                <Box display="flex" flexDirection="row" gap={2}>
                  <img
                    src={ProductAddImagePlaceholder}
                    alt="Add Product Video Placeholder"
                    height="auto"
                    width="150px"
                    style={{ cursor: "pointer" }}
                    onClick={() => videoInputRef.current.click()}
                  />
                  {!video ? (
                    <img
                      src={ProductVideoPlaceholder}
                      alt="Product Video Placeholder"
                      height="auto"
                      width="150px"
                    />
                  ) : (
                    <Stack direction="row" alignItems="center">
                      <video
                        src={video}
                        alt="Product Video"
                        height="auto"
                        width="150px"
                        controls
                      />
                      <Box height="100%">
                        <IconButton
                          disabled={isLoading}
                          onClick={handleDeleteVideo}
                        >
                          <RemoveCircle sx={{ color: "#ff0000" }} />
                        </IconButton>
                      </Box>
                    </Stack>
                  )}
                </Box>
              ) : (
                <Box
                  height="150px"
                  width="150px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Box>
              )}
              <input
                type="file"
                ref={videoInputRef}
                style={{ display: "none" }}
                id="update-product-video"
                hidden
                accept="video/mp4,video/x-m4v,video/*"
                onChange={videoUploadHandler}
              />
            </Stack>
          </Stack>
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

MediaSection.propTypes = {
  formik: PropTypes.object.isRequired,
  handleDisabled: PropTypes.func.isRequired,
};

export default memo(MediaSection, arePropsEqual);

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.formik.values.id === nextProps.formik.values.id &&
    prevProps.formik.values.mainImage === nextProps.formik.values.mainImage &&
    prevProps.formik.errors.mainImage === nextProps.formik.errors.mainImage
  );
}
