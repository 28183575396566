import {
  LCDSizeOptions,
  RAMSizeOptions,
  ROMSizeOptions,
  colorOptions,
  fashionSizeOptions,
  footwearSizeOptions,
  weightOptions,
} from "utils/constants/productForm";

export const reduceAttributes = (values) => {
  const attributes = [];
  if (values.attributes?.length) {
    values.color.length &&
      attributes.push({
        name: "color",
        values: values.color?.map((color) => {
          return color.value;
        }),
      });
    values.size.length &&
      attributes.push({
        name: "size",
        values: values.size?.map((size) => size.value),
      });
    values.weight.length &&
      attributes.push({
        name: "weight",
        values: values.weight?.map((weight) => weight.value),
      });
    values.ram.length &&
      attributes.push({
        name: "ram",
        values: values.ram?.map((ram) => ram.value),
      });
    values.rom.length &&
      attributes.push({
        name: "rom",
        values: values.rom?.map((rom) => rom.value),
      });
  }

  return attributes;
};

export const reducePackageInfo = (values) => {
  const packageInfo = {
    ...(values?.packageWeight && {
      weight:
        values?.packageWeightUnit === "g"
          ? values?.packageWeight / 1000
          : values?.packageWeight,
    }),
  };

  if (
    !values?.packageVolumeUnit ||
    values?.packageVolumeUnit?.value === "none"
  ) {
    packageInfo.volume = {
      unit: "none",
      length: 0,
      width: 0,
      height: 0,
    };

    return packageInfo;
  }

  const volume = {
    ...(values.packageVolumeUnit && {
      unit: values?.packageVolumeUnit?.value,
    }),
    ...(values.packageLength && { length: values?.packageLength }),
    ...(values.packageWidth && { width: values?.packageWidth }),
    ...(values.packageHeight && { height: values?.packageHeight }),
  };
  if (Object.keys(volume).length) {
    packageInfo.volume = volume;
  }

  return packageInfo;
};

export const reduceWarranty = (values) => {
  if (
    !values.warrantyType ||
    !values.warrantyPeriod ||
    values.warrantyType?.value === "none" ||
    values.warrantyPeriod?.value === "none"
  ) {
    return {
      warrantyType: "none",
      warrantyPeriod: {
        unit: "none",
        tenure: 0,
      },
    };
  }

  const warranty = {
    warrantyType: values.warrantyType?.value ?? "",
    warrantyPeriod: {
      unit:
        values.warrantyType?.value && values.warrantyPeriod?.value
          ? values.warrantyPeriod?.value
          : "",
      tenure:
        values.warrantyType?.value && values.warrantyTenure
          ? values.warrantyTenure
          : 0,
    },
  };
  return warranty;
};

export const expandColorAttribute = (attribute) => {
  let arr = [];
  const values = attribute
    .filter((obj) => obj.name === "color")
    ?.map((obj) => obj?.values);
  values?.forEach((item) => {
    item?.forEach((element) => {
      const obj = colorOptions.find((item) => item.value === element);
      if (obj) {
        arr.push(obj);
      }
    });
  });
  return arr;
};

export const expandWeightAttribute = (attribute) => {
  let arr = [];
  const values = attribute
    .filter((obj) => obj.name === "weight")
    ?.map((obj) => obj?.values);
  values?.forEach((item) => {
    item?.forEach((element) => {
      const obj = weightOptions.find((item) => item.value === element);
      if (obj) {
        arr.push(obj);
      }
    });
  });
  return arr;
};

export const expandRamAttribute = (attribute) => {
  let arr = [];
  const values = attribute
    .filter((obj) => obj.name === "ram")
    ?.map((obj) => obj?.values);
  values?.forEach((item) => {
    item?.forEach((element) => {
      const obj = RAMSizeOptions.find((item) => item.value === element);
      if (obj) {
        arr.push(obj);
      }
    });
  });
  return arr;
};

export const expandRomAttribute = (attribute) => {
  let arr = [];
  const values = attribute
    .filter((obj) => obj.name === "rom")
    ?.map((obj) => obj?.values);
  values?.forEach((item) => {
    item?.forEach((element) => {
      const obj = ROMSizeOptions.find((item) => item.value === element);
      if (obj) {
        arr.push(obj);
      }
    });
  });
  return arr;
};

export const expandSizeAttribute = (attribute, categoryTree) => {
  if (!attribute || !categoryTree) {
    return [];
  }
  let arr = [];
  if (
    categoryTree.find((category) => category === "647ee7b89c9a0126548ea9d0") // footwear category
  ) {
    const values = attribute
      .filter((obj) => obj.name === "size")
      ?.map((obj) => obj?.values);
    values?.forEach((item) => {
      item.forEach((element) => {
        const obj = footwearSizeOptions.find((item) => item.value === element);
        if (obj) {
          arr.push(obj);
        }
      });
    });
    return arr;
  }
  if (
    categoryTree.find((category) => category === "647ee7a69c9a0126548ea776") // fashion category
  ) {
    const values = attribute
      .filter((obj) => obj.name === "size")
      ?.map((obj) => obj?.values);
    values?.forEach((item) => {
      item?.forEach((element) => {
        const obj = fashionSizeOptions.find((item) => item.value === element);
        if (obj) {
          arr.push(obj);
        }
      });
    });
    return arr;
  }
  if (
    categoryTree.find(
      (category) =>
        category === "647ee7d49c9a0126548eabdf" || "647ee7d49c9a0126548eabe0"
    ) // LCD category
  ) {
    const values = attribute
      .filter((obj) => obj.name === "size")
      ?.map((obj) => obj?.values);
    values?.forEach((item) => {
      item?.forEach((element) => {
        const obj = LCDSizeOptions?.find((item) => item.value === element);
        if (obj) {
          arr.push(obj);
        }
      });
    });
    return arr;
  }
};
